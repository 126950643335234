export const APP_URL = `${process.env.GATSBY_WIW_APP_URL}`;
export const API_ENDPOINT = `${process.env.GATSBY_WIW_API_ENDPOINT}`;
export const PLATFORM_API_URL = `${process.env.GATSBY_WIW_PLATFORM_URL}`;
export const LOGIN_URL = `${process.env.GATSBY_WIW_LOGIN_URL}`;
export const RESUBMIT_WITHOUT_PLACE_DATA = 'resubmit_without_place_data';
export const CANCEL_SIGNUP = 'cancel_signup';
export const DUPLICATE_EMAIL_ERROR = 'duplicate_email';
export const DUPLICATE_PLACE_DATA_ERROR = 'duplicate_place';
export const UNKNOWN_SIGNUP_ERROR = 'unexpected_signup_error';
export const INVALID_PROMO_CODE = 5010;
export const MISSING_TIMEZONE = 5030;
export const EMAIL_ALREADY_EXISTS = 5140;
export const PLACE_ALREADY_CLAIMED = 2721;

export enum SignUpPlanIds {
  PREMIUM_TRIAL = '-3002', // 2023 pricing
  PREMIUM_TRIAL_2024v2 = '-3202', // 2024v2 pricing
  PREMIUM_TRIAL_2025 = '-4001' // 2025 pricing
}

export enum SignUpTrialLength {
  DEFAULT_TRIAL_LENGTH = 14,
  REFER_TRIAL_LENGTH = 30,
  TRIAL_LENGTH_45_DAY = 45,
}
