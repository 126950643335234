import { QueryClient, QueryClientProvider } from 'react-query';

import withLDProvider from 'utils/launch-darkly/withLDProvider';

import { LoginProvider } from '../context/login-status';

// The left most wrapper will be the inner-most wrapper
// Right wrapper will be on the outside
const rootWrappers = wrapRootMultiple([
  outerLimitWrapper,
  launchdarklyWrapper,
  reactQueryWrapper,
  loginProviderWrapper,
]);

export function outerLimitWrapper({ element }) {
  return <div data-section="youHaveReachedTheOuterLimits">{element}</div>;
}

export function loginProviderWrapper({ element }) {
  return <LoginProvider>{element}</LoginProvider>;
}

function reactQueryWrapper({ element }) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={ queryClient }>
      {element}
    </QueryClientProvider>
  );
}

function launchdarklyWrapper({ element }) {
  const App = () => <>{element}</>;
  const LDApp = withLDProvider()(App);
  return <LDApp />;
}

// Takes an array of "root wrapping functions" with the signature:
// ({element}) => <Component>{element}</Component>
// and wraps the root. The left most function will be ran first and thus be the inner-most
function wrapRootMultiple(rootWrapFunctions) {
  return function WrappedRoot({ element }) {
    return rootWrapFunctions.reduce((ComposedNode, Wrapper) => {
      return <Wrapper element={ ComposedNode } />;
    }, element);
  };
}

export default rootWrappers;
